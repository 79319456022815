<template>
<div class="container">
    <div class="row btm-margin">
        <div class="col-md-3"></div>
        <div class="col-md-6 tp-margin">
            <h1>Register</h1>
        </div>
        <div class="col-md-3"></div>
    </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
            <b-form @submit.prevent="onRegister" @reset.prevent="onReset">
                <b-form-group id="input-group-username" label-cols-sm="3" label="Username:" label-for="username">
                    <b-form-input id="username" v-model="$v.form.username.$model" type="text" :state="validateState('username')"></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.form.username.required">
                        Username is required
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback v-else-if="!$v.form.username.length">
                        Username length should be between 3-8 characters long
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback v-if="!$v.form.username.alpha">
                        Username alpha
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-Password" label-cols-sm="3" label="Password:" label-for="password">
                    <b-form-input id="password" type="password" v-model="$v.form.password.$model" :state="validateState('password')"></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.form.password.required">
                        Password is required
                    </b-form-invalid-feedback>
                    <b-form-text v-else-if="$v.form.password.$error" text-variant="info">
                        Your password should be <strong>strong</strong>. <br />
                        For that, your password should contain one digit and one special character.
                    </b-form-text>
                    <b-form-invalid-feedback v-if="$v.form.password.required && !$v.form.password.length">
                        Have length between 5-10 characters long
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group id="input-group-confirmedPassword" label-cols-sm="3" label="Confirm Password:" label-for="confirmedPassword" style="">
                    <b-form-input id="confirmedPassword" type="password" v-model="$v.form.confirmedPassword.$model" :state="validateState('confirmedPassword')"></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.form.confirmedPassword.required">
                        Password confirmation is required
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback v-else-if="!$v.form.confirmedPassword.sameAsPassword">
                        The confirmed password is not equal to the original password
                    </b-form-invalid-feedback>

                </b-form-group>
                <b-form-group id="input-group-email" label-cols-sm="3" label="email:" label-for="email">
                    <b-form-input id="email" type="text" v-model="$v.form.email.$model" :state="validateState('email')"></b-form-input>
                    <b-form-invalid-feedback v-if="!$v.form.email.required">
                        Email is required
                    </b-form-invalid-feedback>
                    <b-form-invalid-feedback v-else-if="!$v.form.email.verifyMail">
                        Email body is incorrect
                    </b-form-invalid-feedback>
                </b-form-group>

                <b-button type="reset" variant="info">Reset</b-button>
                <b-button type="submit" variant="dark" style="width:250px;" class="ml-5 w-75">Register</b-button>
                <div class="mt-2">
                    You have an account already?
                    <router-link to="login"> Log in here</router-link>
                </div>
            </b-form>
            <b-alert class="mt-2" v-if="form.submitError" variant="warning" dismissible show>
                Register failed: {{ form.submitError }}
            </b-alert>
        </div>
        <div class="col-md-3"></div>
    </div>
</div>
</template>

<script>
import {
    required,
    minLength,
    maxLength,
    alpha,
    sameAs,
    email
} from "vuelidate/lib/validators";

export default {
    name: "Register",
    data() {
        return {
            form: {
                username: "",
                password: "",
                confirmedPassword: "",
                email: "",
                submitError: undefined
            },
            countries: [{
                value: null,
                text: "",
                disabled: true
            }],
            errors: [],
            validated: false
        };
    },
    validations: {
        form: {
            username: {
                required,
                length: (u) => minLength(3)(u) && maxLength(8)(u),
                alpha
            },
            password: {
                required,
                length: (p) => minLength(5)(p) && maxLength(10)(p)
            },
            confirmedPassword: {
                required,
                sameAsPassword: sameAs("password")
            },
            email: {
                required,
                verifyMail: email
            }
        }
    },
    mounted() {
        // console.log("mounted");
        // console.log($v);
    },
    methods: {
        validateState(param) {
            const {
                $dirty,
                $error
            } = this.$v.form[param];
            return $dirty ? !$error : null;
        },
        async Register() {
            try {   
                this.axios.post(this.$store.state.server_domain + "register", {
                    username: this.form.username,
                    password: this.form.password,
                    email: this.form.email
                }, this.$store.state.config).then((res) => {
                    if (res.data.data) {
                        this.$store.state.userId = res.data[0];
                        this.$root.store.login(this.form.username);
                        this.$router.push("/");
                        this.$root.toast("Register", `Welcome ${this.form.username}`, "success");
                    } else {
                        this.$root.toast("Register", `${res.data.error}`, "danger");
                    }
                }).catch((err) => {
                    this.$root.toast("Register", `${err.response.data.error}`, "danger");
                });
            } catch (err) {
                this.$root.toast("Register", `${err.response.data.error}`, "danger");
            }
        },
        onRegister() {
            // console.log("register method called");
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            // console.log("register method go");
            this.Register();
        },
        onReset() {
            this.form = {
                username: "",
                password: "",
                confirmedPassword: "",
                email: ""
            };
            this.$nextTick(() => {
                this.$v.$reset();
            });
        }
    }
};
</script>

<style>
.btm-margin {
    margin-bottom: 3%;
}

.tp-margin {
    margin-top: 3%;
}
</style>
